.QualityProj {
  display: table;
  position: relative;
  height: auto;
  max-width: 90%;
}

.QualityProj > img {
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  width: auto;
  height: auto;
}

.CursorPointer {
  cursor: pointer;
}

.dashed {
  position: absolute;
  stroke-dasharray: 8, 6;
}

.nipple_cc {
  left: 10%;
  top: 43%;
}

.nipple_cc_top {
  left: 10%;
  top: 39%;
}

.nipple_cc_bottom {
  left: 10%;
  top: 47%;
}

#nipple_mlo {
  left: 7%;
  top: 58%;
}

#pecto_cc {
  right: 0%;
  top: 9.6%;
}

#infram {
  right: 0%;
  bottom: 1%;
}

#angle {
  right: 0%;
  top: 23%;
}

.svg_pecto {
  position: absolute;
  width: 18%;
  height: 28%;
  z-index: 5;
  right: 1.5%;
  bottom: 24%;
  stroke-width: 5%;
  transform: rotate(164deg);
}

*:disabled {
  cursor: auto;
  box-shadow: none !important;
}

.pect_convexity {
  position: absolute;
  width: 22%;
  height: 56%;
  z-index: 5;
  right: -2%;
  bottom: 50%;
  stroke-width: 5%;
  transform: rotate(-11deg);
}
